
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        column: {
            type: Array as PropType<{prop: string; label: string; show?: boolean}[]>,
            default: () => ([]),
            require: true
        },
        data: {
            type: Object,
            default: () => ({}),
            require: true
        }
    }
});

