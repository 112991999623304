
import { reactive, PropType, defineComponent } from 'vue';
import PanelList from '@/components/common/panel-list';
import { billingCycleMap } from '@/constant/field-map';

export default defineComponent({
    props: {
        autoRenewInfo: {
            type: Object as PropType< {
                Users: string;
                IntervalType: number;
                Cycles: number;
                StartTime: string;
                TotalPrice: string;
                SubscriptionUUID: string;
                InstallerUUID: string;
            }>,
            default: () => ({}),
            require: true
        },
        // dis、subdis需要传递InstallerUUID
        installerUUID: {
            type: String,
            default: ''
        },
        // 得到选择的列表item上的区号
        info: {
            required: true,
            type: Object as PropType<Record<string, any>>
        }
    },
    components: {
        PanelList
    },
    setup() {
        const panelListColumn = reactive([
            { prop: 'IntervalType', label: WordList.RenewType, show: true },
            // { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime, show: true },
            { prop: 'Cycles', label: WordList.RenewTimes, show: true },
            { prop: 'number', label: WordList.RenewChooseAptNumber, show: true },
            { prop: 'TotalPrice', label: WordList.TotalPriceByMonth, show: true }
        ]);
        return { panelListColumn, billingCycleMap };
    }
});

