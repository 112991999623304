import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_panel_list = _resolveComponent("panel-list")

  return (_openBlock(), _createBlock(_component_panel_list, {
    column: _ctx.panelListColumn,
    data: _ctx.autoRenewInfo
  }, {
    IntervalType: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.billingCycleMap[_ctx.autoRenewInfo?.IntervalType]) + " (" + _toDisplayString(_ctx.info.item && _ctx.info.item[0].TimeZone) + ") ", 1)
    ]),
    Cycles: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.autoRenewInfo['Cycles']===0?_ctx.$wordList.Unlimited:_ctx.autoRenewInfo['Cycles']), 1)
    ]),
    TotalPrice: _withCtx(() => [
      _createVNode("span", null, "$" + _toDisplayString(_ctx.autoRenewInfo.TotalPrice), 1)
    ]),
    number: _withCtx(() => [
      _createTextVNode(_toDisplayString(JSON.parse(_ctx.autoRenewInfo.Users).length), 1)
    ]),
    _: 1
  }, 8, ["column", "data"]))
}